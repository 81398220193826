* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.padding-40 {
  padding: 40px;
}

/* html {
  font-size: calc(15px + 0.78125vw);
} */

html {
  font-size: calc(10px + 0.390625vw);
  font-family: "Poppins";
}

@media only screen and (min-width: 1536px) {
  html {
    /* font-size: 20px; */
  }
}

.portal-component {
  width: 100%;
  top: 0;
  z-index: 1;
  position: absolute;
}


/* Scrollbar Styling */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e7e7ed;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e7e7ed;
}
