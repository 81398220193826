.PhoneInputInput {
    font-size: 1rem;
    line-height: 1.5rem;
    background-color: #F5F8FF;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid #E7E7ED; 
    width: 100%;
    box-sizing: border-box;
    height: 2.5rem
}